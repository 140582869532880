<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="d-none d-md-block">
      <hero-component
        :btn-text="$t('caTermPage.heroImage.buttonText')"
        background-url="/img/term-life-2022/Hero-Section.jpg"
        background-position="top"
        btn-link="/quote"
        align="flex-start"
        class="hero-desktop"
      >
        <p
          class="sora desktop"
          v-html="$t('caTermPage.heroImage.text1')"
        >
        </p>
      </hero-component>
      <div class="section2 pb-16">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                {{ $t('caTermPage.header.title') }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                {{ $t('caTermPage.header.subSection') }}
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex justify-center mt-16 mb-n6">
          <v-col
            xs="12"
            sm="10"
            md="12"
            lg="10"
            xl="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-account-cash
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-certificate
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-tablet-cellphone
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-handshake-outline
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-help-box
              </v-icon>
            </v-col>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            xs="12"
            sm="10"
            md="12"
            lg="10"
            xl="10"
            class="d-inline-flex align-start"
          >
            <v-col class="d-flex justify-center align-top-content">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText1') }}
              </div>
            </v-col>
            <v-col class="d-flex justify-center align-top-content">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText5') }}
              </div>
            </v-col>
            <v-col class="d-flex justify-center align-top-content">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText2') }}
              </div>
            </v-col>
            <v-col class="d-flex justify-center align-top-content">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText3') }}
              </div>
            </v-col>
            <v-col class="d-flex justify-center align-top-content">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText4') }}
              </div>
            </v-col>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center mt-0">
          <v-col
            xs="12"
            sm="10"
            md="12"
            lg="10"
            xl="10"
            class="d-inline-flex align-start"
          >
            <v-col class="d-flex flex-column text-center s2-desc">
              {{ $t('caTermPage.header.iconTextContent1') }}
            </v-col>
            <v-col
              class="d-flex flex-column text-center s2-desc"
              v-html="$t('caTermPage.header.iconTextContent5')"
            >
            </v-col>
            <v-col class="d-flex flex-column text-center s2-desc">
              {{ $t('caTermPage.header.iconTextContent2') }}
            </v-col>
            <v-col class="d-flex flex-column text-center s2-desc">
              {{ $t('caTermPage.header.iconTextContent3') }}
            </v-col>
            <v-col class="d-flex flex-column text-center s2-desc">
              {{ $t('caTermPage.header.iconTextContent4Part1') }} <span style="margin-right: 5px;"></span><a
                class="primary--text text-decoration-underline"
                @click="goToFaqs('faq')"
              > {{ $t('caTermPage.header.iconTextContent4Part2') }}</a>
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              x-large
              @click="navigate('/quote',$gtag)"
            >
              {{ $t('caTermPage.applyNow') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col cols="6">
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  {{ $t('caTermPage.section.title1') }}
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  {{ $t('caTermPage.section.subSection1') }}
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in termLifeNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  {{ $t('caTermPage.section.title2') }}
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in processingFeatures"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="videoData"
            cols="6"
          >
            <video
              controls
              width="640"
              height="360"
            >
              <source
                :src="videoData"
                type="video/mp4"
              >
            </video>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col
            class="sec5-title"
          >
           <span v-html="$t('caTermPage.section.bottomSectionTitle')"></span><br />
            {{ $t('caTermPage.covered') }}
          </v-col>
          <v-col class="sec5-sub">
            {{ $t('caTermPage.section.bottomSectionText') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            {{ $t('caTermPage.section.bottomSectionText') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub-button">
            <v-btn
              large
              class="mint apply-btn ml-4"
              @click="navigate('/quote',$gtag)"
            >
              <span
                class="text-h4 font-weight-bold darkgrey--text custom-width"
              >{{ $t('caTermPage.section.buttonButtonText') }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          src="/img/renters-2022/Mobile-app-mockup.png"
          :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone hidden-on-medium-screens'"
        ></v-img>
      </div>
      <div
        id="faq"
        class="white pt-16 pb-16"
      >
        <div
          v-for="(faq, index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-desk ml-4"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class="text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon color="#005F6C">
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br />
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------TABLET------------------------------------------------------>
    <div v-show="tabletDevice">
      <div class="section1-tablet sora">
        <div
          class="sora-tablet white--text"
          v-html="$t('caTermPage.heroImage.text1')"
        >
        </div>
        <div class="cta-btn-tablet">
          <v-btn
            class="mint darkgrey--text font-weight-bold text-h4"
            large
            @click="navigate('/quote',$gtag)"
          >
            {{ $t('caTermPage.heroImage.buttonText') }}
          </v-btn>
        </div>
      </div>
      <div class="section2-tablet pb-16 px-2">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                {{ $t('caTermPage.header.title') }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                {{ $t('caTermPage.header.subSection') }}
              </div>
            </v-col>
          </v-row>
        </div>

        <v-row class="d-flex align-start justify-center mt-16 mb-n6">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-account-cash
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-certificate
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-tablet-cellphone
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-handshake-outline
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="primary--text s2-icon">
                mdi-help-box
              </v-icon>
            </v-col>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="12"
            class="d-inline-flex align-start"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText1') }}
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText5') }}
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead text-center">
                <!-- 10-Day Free Look <br> Period -->
                {{ $t('caTermPage.header.iconText2') }}
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText3') }}
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead text-center">
                {{ $t('caTermPage.header.iconText4') }}
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex text-center s2-desc-tablet">
              {{ $t('caTermPage.header.iconTextContent1') }}
            </v-col>
            <v-col
              class="d-flex flex-column text-center s2-desc-tablet"
              v-html="$t('caTermPage.header.iconTextContent5')"
            >
            </v-col>
            <v-col class="d-flex text-center s2-desc-tablet">
              {{ $t('caTermPage.header.iconTextContent2') }}
            </v-col>
            <v-col class="d-flex text-center s2-desc-tablet">
              {{ $t('caTermPage.header.iconTextContent3') }}
            </v-col>
            <v-col class="d-flex text-center s2-desc-tablet">
              {{ $t('caTermPage.header.iconTextContent4Part1') }} <span style="margin-right: 5px;"></span><a
                class="primary--text text-decoration-underline"
                @click="goToFaqs('tabletFaq')"
              >{{ $t('caTermPage.header.iconTextContent4Part2') }}</a>
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              x-large
              @click="navigate('/quote',$gtag)"
            >
              {{ $t('caTermPage.applyNow') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  {{ $t('caTermPage.section.title1') }}
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  {{ $t('caTermPage.section.subSection1') }}
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in termLifeNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <!-- <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  <h1>Additional options include:</h1>
                </div>
              </v-col>
            </v-row> -->

            <!-- <v-row class="d-flex justify-center">
              <v-col
                v-for="item in optionalCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row> -->
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  {{ $t('caTermPage.section.title2') }}
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in processingFeatures"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-if="videoData"
          class="d-flex justify-start"
        >
          <v-col>
            <video
              controls
              width="640"
              height="360"
            >
              <source
                :src="videoData"
                type="video/mp4"
              >
            </video>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            <span v-html="$t('caTermPage.section.bottomSectionTitle')"></span><br />
            {{ $t('caTermPage.covered') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            {{ $t('caTermPage.section.bottomSectionText') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              @click="navigate('/quote',$gtag)"
            >
              <span
                class="text-h4 font-weight-bold darkgrey--text max-width-style"
              > {{ $t('caTermPage.section.buttonButtonText') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        id="tabletFaq"
        class="white pt-16 pb-16"
      >
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq, index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title ml-2"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-tablet"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class="text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon color="#005F6C">
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br />
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------MOBILE------------------------------------------------------>
    <div v-if="mobileDevice">
      <div class="section1-mobile"></div>
      <div class="section2-mobile">
        <div class="section2-title-mobile">
          <v-row class="mb-5">
            <v-col class="pt-5 sora">
              <p v-html="$t('caTermPage.heroImage.text1')"></p>
            </v-col>
          </v-row>
          <v-btn
            x-large
            class="mint mx-auto py-8"
            @click="navigate('/quote',$gtag)"
          >
            <span
              class="section2-btn-txt-mobile pt-1 font-mobile"
            >{{ $t('caTermPage.heroImage.buttonText') }}</span>
          </v-btn>
        </div>
      </div>
      <div class="section3-mobile">
        <v-row class="pt-10">
          <v-col class="section3-title-mobile">
            {{ $t('caTermPage.header.title') }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="section3-sub-mobile">
            {{ $t('caTermPage.header.subSection') }}
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-account-cash
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            {{ $t('caTermPage.header.iconText1') }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            {{ $t('caTermPage.header.iconTextContent1') }}
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-certificate
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            {{ $t('caTermPage.header.iconText5') }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            class="covg-txt-mobile text-center"
            v-html="$t('caTermPage.header.iconTextContent5')"
          >
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              large
              class="primary--text"
            >
              mdi-tablet-cellphone
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            <!-- 10-Day Free Look Period -->
            {{ $t('caTermPage.header.iconText2') }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            {{ $t('caTermPage.header.iconTextContent2') }}
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-handshake-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            {{ $t('caTermPage.header.iconText3') }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            {{ $t('caTermPage.header.iconTextContent3') }}
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-help-box
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            {{ $t('caTermPage.header.iconText4') }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            {{ $t('caTermPage.header.iconTextContent4Part1') }}<span style="margin-right: 5px;"></span><a
              class="primary--text text-decoration-underline"
              @click="goToFaqs('mobileFaq')"
            >{{ $t('caTermPage.header.iconTextContent4Part2') }}</a>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-btn
              class="mint darkgrey--text text-h4 font-weight-bold mr-0 font-mobile"
              @click="navigate('/quote',$gtag)"
            >
              {{ $t('caTermPage.applyNow') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4-mobile pb-10">
        <v-row class="pt-8">
          <v-col class="s4-title-mobile">
            {{ $t('caTermPage.section.title1') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            {{ $t('caTermPage.section.subSection1') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in termLifeNeeds"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="s4-sub-mobile">
            Additional options include:
          </v-col>
        </v-row> -->
        <!-- <v-row>
          <v-col
            v-for="item in optionalCoverages"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row> -->
        <v-row>
          <v-col class="s4-sub-mobile">
            {{ $t('caTermPage.section.title2') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in processingFeatures"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
        <v-row
          v-if="videoData"
          class="d-flex justify-start"
        >
          <v-col>
            <video
              controls
              width="320"
              height="260"
            >
              <source
                :src="videoData"
                type="video/mp4"
              >
            </video>
          </v-col>
        </v-row>
      </div>
      <div class="s5-mobile">
        <v-row class="pt-10">
          <v-col class="s5-title-mobile">
            <span v-html="$t('caTermPage.section.bottomSectionTitle')"></span><br />
            {{ $t('caTermPage.covered') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s5-sub-mobile">
            {{ $t('caTermPage.section.bottomSectionText') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center pt-6">
            <v-btn
              x-large
              class="mint mx-auto py-8"
              max-width="300"

              @click="navigate('/quote',$gtag)"
            >
              <span
                class="section2-btn-txt-mobile pt-1 font-mobile"
              >{{ $t('caTermPage.section.buttonButtonText') }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          class="phone-mobile"
          src="/img/phone-mobile.png"
        ></v-img>
      </div>
      <div
        id="mobileFaq"
        class="white pt-16 pb-16"
      >
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq, index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-mobile ml-2"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class="text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon color="#005F6C">
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br />
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
  </v-container>
</template>

<script>
  import HeroComponent from '@/components/base/Hero'
  import { GT_FORM_START, gtag_report_conversion } from '../../../utils/common'

  export default {
    name: 'Dashboard',
    components: { HeroComponent },

    data () {
      return {
        productDetails: true,
        videoUrl: '',
        productDetailsFields: [
          { label: 'Product Design' },
          { label: 'Minimum Face Amount' },
          { label: 'Maximum Face Amount' },
          { label: 'Term Length' },
          { label: 'Renewability' },
          { label: 'Banding' },
          { label: 'Policy Fee' },
          { label: 'Underwriting Process' },
          { label: 'Monthly Payment Modal Factor' },
          { label: 'Risk' },
          { label: 'Non-Nicotine Classes' },
          { label: 'Nicotine Classes' },
          { label: 'Accelerated Death Benefit Rider' },
          { label: 'Conversion Option' },
        ],
        covid19Faq: false,
        covFaq: false,
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
        termLifeNeeds: [
          this.$t('caTermPage.section.bulletPoint1'),
          this.$t('caTermPage.section.bulletPoint2'),
          this.$t('caTermPage.section.bulletPoint3'),
          this.$t('caTermPage.section.bulletPoint4'),
        ],
        optionalCoverages: [
          'LegacyShield is a digital vault service and legacy preparation tools to help you better organize your life today and more easily plan for tomorrow.',
          'Accelerated Death Benefit Rider expedites a portion of the policy’s death benefit if you become terminally ill.',
        ],
        processingFeatures: [
          this.$t('caTermPage.section.bulletPoint_2_1'),
          this.$t('caTermPage.section.bulletPoint_2_2'),
          this.$t('caTermPage.section.bulletPoint_2_3')
        ],
        mainFaqList: [
          {
            status: true,
            title: this.$t('caTermPage.header.iconTextContent4Part2'),
            list: [
              {
                ques: this.$t('caTermPage.faqSection.question1.text'),
                ans:
                  this.$t('caTermPage.faqSection.question1.answer'),
              },
              {
                ques: this.$t('caTermPage.faqSection.question2.text'),
                ans:
                  this.$t('caTermPage.faqSection.question2.answer')
              },
              {
                ques: this.$t('caTermPage.faqSection.question3.text'),
                ans: this.$t('caTermPage.faqSection.question3.answer')
              },
              {
                ques: this.$t('caTermPage.faqSection.question4.text'),
                ans: this.$t('caTermPage.faqSection.question4.answer')
              },
              {
                ques: this.$t('caTermPage.faqSection.question5.text'),
                ans: this.$t('caTermPage.faqSection.question5.answer')
              },
              {
                ques: this.$t('caTermPage.faqSection.question6.text'),
                ans: this.$t('caTermPage.faqSection.question6.answer'),
              },
              {
                ques: this.$t('caTermPage.faqSection.question7.text'),
                ans: this.$t('caTermPage.faqSection.question7.answer')
              },
              {
                ques: this.$t('caTermPage.faqSection.question8.text'),
                ans: this.$t('caTermPage.faqSection.question8.answer')
              },
            ],
          },
        ],
      }
    },
    computed: {
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      largeDevice () {
        return this.$vuetify.breakpoint.lgAndUp
      },
      mediumDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
      videoData () {
        return this.videoUrl
      }
    },
    async mounted () {
      this.currentuser = this.$store.getters.getuser
      const {
        partnerId = null
      } = this.$route.params
      this.$store.commit('setPartnerId', partnerId ?? this.$store.getters.getPartnerId)
      const videoUrl = await this.$store.dispatch('getCaTermVideo')
      this.videoUrl = videoUrl
    },
    methods: {

      gtag_report_conversion,
      navigate (link, gtag) {
        gtag_report_conversion(gtag, GT_FORM_START)
        console.log(link, gtag)
        this.$router.push(link)
      },
      goToFaqs (sectionId) {
        const targetSection = document.getElementById(sectionId)
        if (targetSection) {
          console.log('targetSection', targetSection)
          targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      },
      payNow () {
        this.$store.dispatch('makeCATermCreditCardPayment')
      },
      openQuoteCalculator () {
        try {
          const url = '/canadianterm/coveragecalculator'
          const target = '_blank'

          const width = window.innerWidth * 0.25 // 50% of viewport width
          const height = window.innerHeight

          const features = `width=${width},height=${height}`
          window.open(url, target, features)
        } catch (error) {
          console.error('Failed to open quote calculator', error)
        }
      },
      toggleProductDetails () {
        this.productDetails = !this.productDetails
      },
      toggleFaq (index) {
        this.mainFaqList[index].status = !this.mainFaqList[index].status
      },
    },
  }
</script>
<style scoped>
.s2-icon {
  font-size: 50px !important;
}
.font-mobile{
  font-size: 14px!important;
  white-space: normal;
  max-width: 300px;
}

.apply-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22.6667px;
  line-height: 125%;
  /* or 28px */

  text-align: center;
  letter-spacing: 0.944445px;
  text-transform: uppercase;

  /* Color Palette/Dark Grey */

  color: #292f36;
}

.s2-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #292f36;
}

.s2-desc-tablet {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #292f36;
}
.s2-subhead {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #005f6c;
}
.s2-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.cta-btn {
  position: absolute;
  top: 78%;
  left: 9%;
}

.cta-txt {
  font-weight: 700;
  font-size: 17.9859px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.749412px;
  text-transform: uppercase;
  color: #292f36;
  padding: 0 !important;
}

.section2-header {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.42px;
  color: #292f36;
}

.s2-title {
  padding-top: 79px;
}
.title-text-mobile {
  font-weight: 500;
  color: #616161;
}
.cover {
  font-size: 55px;
  color: #757575;
}
.read-more {
  font-size: 18px;
  color: #00a1b7;
}
.quote-btn {
  font-weight: bold;
}
.section2 {
  position: relative;
  background-image: url("/img/pattern-white-background.png");
  background-position: center;
  background-size: cover;
  mix-blend-mode: normal;
  /* background-color: #FFFFFF; */
}
.spacer {
  height: 6rem;
  z-index: 2;
}

.title-text-tablet {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
}
.pet-text {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #757575;
  z-index: 1;
  background-color: white;
}
.learn {
  border: 1px solid #00a1b7 !important;
  color: #00a1b7 !important;
}
.btn-mrg1 {
  margin-left: 0;
}
.btn-mrg2 {
  margin-left: 200px;
}
.cov-grp {
  background-color: white;
}
.small {
  height: 700px;
}
.large {
  height: 900px;
}
.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.2);
}

.section3 {
  position: relative;
  height: 700px;
  background-color: #005f6c;
}

.review-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #ffffff;
}

.review-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */

  letter-spacing: -0.424019px;

  /* white */

  color: #ffffff;
}

.review-details {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
  color: #ffffff;
}

.review-sig {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 140%;
  /* or 29px */

  /* white */

  color: #ffffff;
}

.review-ins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
  /* or 29px */

  /* white */

  color: #ffffff;
}

.portrait-card {
  position: absolute;
  width: 615px;
  height: 700px;
  left: 880px;
  top: -30px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(1, 0, 0, 0)),
    url("/img/pet-review-portrait.png");
  border-radius: 10px;
}

.portrait-quote {
  width: 300px;
  height: 364px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  /* or 72px */

  letter-spacing: -0.636172px;

  /* Color Palette/White (100%) */

  color: #ffffff;
}

.portrait-text-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 90px 20px 30px;
  width: 100%;
  height: 251px;
  background: #00a1b7;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  z-index: 3;
  border-radius: 0 0 0 10px;
}

.portrait-text {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-size: 17.9695px;
  line-height: 125%;
  letter-spacing: -0.202611px;
  color: #ffffff;
}

.signature-container {
  position: absolute;
  right: 10rem;
  top: 42rem;
}

.section4 {
  background: #ffffff;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 163px;
}

.sec4-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  /* or 42px */

  letter-spacing: -0.424019px;

  /* Color Palette/Dark Grey */

  color: #292f36;
}

.sec4-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.covg-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #292f36;
}

.covg-cta-txt {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  /* or 42px */

  letter-spacing: -0.424019px;

  /* midnight eagle green */

  color: #005f6c;
}

.learn-btn-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 125%;
  /* identical to box height, or 25px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;

  /* gunmetal */

  color: #292f36;
}

.sec5-dsk {
  position: relative;
  height: 438px;
  background-image: url("/img/partner-background.jpg");
  background-position: center;
  background-size: cover;
  padding-left: 10%;
  padding-right: 10%;
}

.sec5-dsk-phone {
  position: absolute;
  left: 780px;
  top: -78px;
}

.sec5-xl-phone {
  position: absolute;
  left: 1100px;
  top: -78px;
  display: none;
}

.sec5-title {
  position: absolute;
  left: 130px;
  top: 0px;
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  /* or 46px */

  letter-spacing: -0.424019px;

  /* Color Palette/White (100%) */

  color: #ffffff;
}

.sec5-sub {
  position: absolute;
  left: 130px;
  top: 131px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 125%;
  width: 598px;
  height: 120px;
  /* or 40px */

  letter-spacing: -0.424019px;

  /* white */

  color: #ffffff;
}
.sec5-sub-button{
   position: absolute;
   left: -8px;
}
.custom-width{
  max-width: 500px;
  white-space: normal;
  margin-top: 5px;
}

.apply-btn {
  position: absolute;
  left: 130px;
  top: 289px;
}

.faq-sec-desk {
  background: #ffffff;
  padding-left: 10%;
  padding-right: 10%;
}

.faq-sec-tablet {
  background: #ffffff;
}

.faq-header-desk {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #005f6c;
}

.faq-panel-icon {
  color: #005f6c;
}

.cov-faq-title:hover {
  cursor: pointer;
}

.poppins-300 {
  font-family: "Poppins";
  font-weight: 300;
}
/*-----------------------------MOBILE STYLES-----------------------------*/
.section1-mobile {
  background-image: url("/img/term-life-2022/Hero-img-tablet.jpg");
  background-position: center;
  background-size: cover;
  height: 375px;
}

.section2-mobile {
  width: 100vw;
  height: 260px;
  background: #00a1b7;
}

.section2-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.511589px;
  color: #ffffff;
}

.section2-btn-txt-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: #292f36;
}

.section3-mobile {
  padding-bottom: 54px;
  background-image: url("/img/knit-mobile.png");
  background-position: center;
  background-size: cover;
  padding-left: 10px;
  padding-right: 10px;
}

.section3-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.511589px;
  color: #292f36;
}

.section3-sub-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.covg-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #005f6c;
}

.covg-txt-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #292f36;
}

.section4-mobile {
  padding-bottom: 54px;
  padding-left: 10px;
  padding-right: 10px;
  background: #ffffff;
}

.s4-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #292f36;
}

.s4-sub-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.covg-txt-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #292f36;
  text-align: left;
}

.panel-title-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #005f6c;
}

.panel-icon {
  color: #292f36;
  font-size: 20px !important;
}

::v-deep .v-expansion-panel-header--active .v-icon {
  color: #ffffff !important;
}

.s5-mobile {
  position: relative;
  min-height: 500px;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url("/img/partner-background-mobile.jpg");
  background-position: center;
  background-size: cover;
}

.s5-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.511589px;
  color: #ffffff;
}

.s5-sub-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #ffffff;
}

.phone-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 300px;
  width: auto;
  display: none;
}

.faq-header-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #005f6c;
}

/*-----------------------------TABLET STYLES-----------------------------*/
.section1-tablet {
  position: relative;
  width: 100vw;
  height: 600px;
  background: url("/img/term-life-2022/Hero-Section.jpg");
  background-position: center;
  background-size: cover;
}

.section2-tablet {
  position: relative;
  background-image: url("/img/pattern-white-background.png");
  background-position: center;
  background-size: cover;
  mix-blend-mode: normal;
  /* background-color: #FFFFFF; */
}

.faq-header-tablet {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #005f6c;
}

.sora-tablet {
  position: absolute;
  top: 45%;
  left: 5%;
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  color: #ffffff;
}

.cta-btn-tablet {
  position: absolute;
  top: 78%;
  left: 5%;
}

::v-deep .v-expansion-panel-header {
  color: #00a1b7;
}

::v-deep .v-expansion-panel--active .v-expansion-panel-header {
  background: #00a1b7;
  color: #ffffff;
}

.product-border {
  border: 12px solid #005F6C;
  border-top: 16px solid #005F6C;
}

.product-border-tablet {
  border-right: 10px solid #005F6C;
  border-left: 10px solid #005F6C;
  border-top: 16px solid #005F6C;
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .hidden-on-medium-screens {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 850px) {
  .sec5-title,
  .sec5-sub {
    left:10px!important;
  }
  .max-width-style{
    width: 400px;
    white-space: normal;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .apply-btn {
    left: -58px!important;
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .s2-subhead{
    font-size: 14px!important;
  }
  .mint.apply-btn,
  .sec5-title,
  .sec5-sub {
    left: 30px!important;
  }
  .sec5-dsk {
    padding-left: 1%!important;
  }
  .sec5-title {
    font-size: 35px!important;
  }
  .sec5-sub {
    font-size: 30px!important;
  }
  .text-h4  {
    font-size: 12px!important;
  }

}

::v-deep .v-size--x-large{
  width: 300px;
}
.sora {
  position: relative;
  top: 35px;
}
.align-top-content {
  align-self: flex-start;
}
@media screen and (min-width: 960px) and (max-width: 1220px) {
   video{
     width: 450px;
     height: 400px;
   }
}
@media screen and (min-width: 1200px) and (max-width: 1330px) {
   video{
     width: 550px;
     height: 500px;
   }
}
@media screen and (min-width: 960px) {
   video{
    margin-top: 240px;
   }
}
.sora.desktop{
  font-size: 62px!important;
}
</style>
